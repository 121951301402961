/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/* stylelint-disable */
.CartRewardsSection {
    margin-block: 22px;
    padding-block: 24px;
    padding-inline: 16px;
    background: #ffffff;
    overflow: hidden;
    .ProductCard {
        max-width: 283px;
        margin: auto;
        @include mobile {
            max-width: 172px;
        }
    }
    @include  mobile {
        padding-inline: 3px;
        box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
        border-radius: 8px;
    }
    &-Header{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-block-end: 20px;
        font-weight: bold;
        @include mobile {
            padding-inline: 14px;
        }
        &Text{
            font-family: 'Lato';
            font-style: normal;
            letter-spacing: 0.05em;
            color: #161616;  
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
            @include mobile {
                font-size: 14px;
            }
        }
        &TimeLeft{
            font-family: 'Lato';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            color: #DE4D47;
            display: flex;
            align-items: center;
            gap: 10px;
            @include mobile {
                font-size: 13px;
            }
        }
    }

    .slick {
        &-slide{
            width: 295px;
            @include mobile {
                width: 280px;
            }
            > div {
                //display: flex;
                //align-items: center;
            }
        }
        &-arrow {
            width: 64px;
            height: 64px;
            z-index: 4;
            @include mobile {
                width: 42px;
                height: 42px;
            }
        }
        &-prev {
            left: 2%;
            opacity: 0.5;
            background: white;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            svg {
                height: auto;
                transform: rotate(180deg);
            }
            &::before {
                display: none;
            }
        }
        &-next {
            right: 2%;
            opacity: 0.5;
            background: white;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            svg {
                height: auto;
            }
            &::before {
                display: none;
            }
        }
    }
    &-Card{
        // .splide__arrows{
        //     position: absolute;
        //     width: 100%;
        //     top: 50%;
        // }
        .slick-list{
            @include mobile {
                padding:0 20px 0 0 !important;
            }
        }
    }
    .ProductCard {
        border: 3px solid #E84179;
        border-radius: 8px;
        display: block;
        overflow: hidden;
        height: 456px;
        @include mobile {
            height: 300px;
        }
    }
}
