/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */
/* stylelint-disable */
:root {
    --cart-page-divider-background: var(--secondary-base-color);
    --cart-page-promo-background: #F09000;
    --cart-page-table-head-background: var(--secondary-base-color);
    --desktop-loader-offset: calc(0px - var(--header-total-height) - var(--breadcrumbs-height) - 32px);
}

// .Breadcrumbs {
//     background-color: #F0EFED;
//     .ContentWrapper {
//         margin-inline: 6%;
//         padding: 0px;
//         max-width: 100%;        
//     }
// }

.CartPage {
    //margin-block-end: var(--footer-total-height);
    padding-bottom: var(--footer-total-height);
    background-color: #F0EFED;

    @include mobile {
        padding-block-end: 0;
        padding-inline: 16px;
    }

    &-Wrapper {
        @include desktop {
            display: grid;
            grid-template-columns: 1fr auto;
            column-gap: 24px;
            //display: grid;
            //grid-template-columns: 1fr 360px;
            //grid-column-gap: 48px;
            //margin-inline: 6%;
            padding: 0px;
            //max-width: 100%;
        }

        @include tablet {
            grid-column-gap: 12px;
        }

        @include mobile {
            padding: 0;
        }
    }

    &-InitialLoaderContainer {
        inset-inline-start: 0;
        inset-block-start: 0;
        z-index: 10;
        position: fixed;

        @include desktop {
            max-width: 100vw;
            max-height: 100vh;
            width: 100vw;
            height: 100vh;
        }

        .Loader {
            margin: none;

            &-Scale {
                @include mobile {
                    position: fixed;
                    inset-block-start: auto;
                    inset-block-end: 50%;

                    &:dir(rtl) {
                        transform: translate(50%, 50%);
                    }

                    &:dir(ltr) {
                        transform: translate(-50%, 50%);
                    }
                }
            }
        }
    }

    &-Empty {
        @include mobile {
            padding: 14px;
            border-block-end: 1px solid var(--expandable-content-divider-color);
            text-align: center;
        }
    }

    &-Summary {
        background: var(--color-white);

        @include mobile {
            inset-inline-start: 0;
            width: 100%;
            padding-inline: 16px;
        }
    }

    &-CheckoutButtons {
        padding: 12px 0;

        @include mobile {
            padding: 14px;
        }
    }

    &-OutOfStockProductsWarning {
        padding: 10px;
        display: flex;
        justify-content: center;
        background-color: var(--primary-error-color, red);
        font-size: 14px;
        font-weight: 600;
        color: var(--color-white);
        inset-inline-start: -16px;
        width: calc(100% + 32px);
    }

    &-CheckoutButton {
        width: 100%;
        text-align: center;

        @include desktop {
            margin: 0px 0;
        }
    }

    &-Promo,
    &-Total {
        min-height: 48px;

        @include mobile {
            min-height: 0;
        }
    }

    &-Promo {
        display: flex;
        order: 0;
        background: var(--cart-page-promo-background);
        min-height: auto;
        justify-content: center;

        @include desktop {
            //margin-block-start: 24px;
        }

        @include mobile {
            //padding: 10px 0;
            margin-inline: -16px;
        }

        &Block {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 4px;
            font-family: 'Lato';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            color: #FFFFFF;

            @include mobile {
                //margin-block: 12px;
                //padding: 14px;
            }
        }

        &Image {
            width: 46px;
            margin-inline-end: 6px;

            @include mobile {
                width: 36px;
                margin-inline-end: 7px;
            }
        }

        strong {
            margin: 0 5px;
        }
    }

    &-Total {
        display: flex;
        justify-content: space-between;
        font-size: 16px;
        align-items: center;
        font-weight: bold;
        padding: 12px 0;

        @include mobile {
            padding-block-start: 14px;
            padding-block-end: 0;
            padding-inline: 14px;
        }

        dd {
            text-align: end;

            span {
                display: block;
                font-size: 12px;
                font-weight: 400;
            }
        }
    }

    &-TableHead {
        display: none;
        grid-template-columns: 3fr 2fr 1fr;
        background-color: var(--cart-page-table-head-background);
        text-transform: capitalize;
        font-size: 14px;
        font-weight: 600;
        padding: 14px 16px;
        margin-block-end: 0;
        border-radius: var(--input-border-radius);

        @include mobile {
            display: none;
        }

        span {
            text-align: start;

            &:last-of-type {
                text-align: end;
            }
        }
    }

    & &-Discount {// hack to obtain required specificity
        border-block-start: 0;

        @include mobile {
            border-block-start: 1px solid var(--expandable-content-divider-color);
            border-block-end: 0;
        }

        @include desktop {
            margin-block-start: 32px;
        }
    }

    &-Floating {
        @include desktop {
            display: flex;
            flex-direction: column;
            max-width: 411px;     
            // margin-block-start: 86px;
        }

        @include tablet {
            margin-block-start: 24px;
        }
    }

    &-Static {
        display: flex;
        flex-direction: column;
        row-gap: 24px;
        @include mobile {
            max-width: 100%;
        }
    }

    &-InnerStatic {
        padding: 24px;
        display: flex;
        flex-direction: column;
        row-gap: 16px;
        background-color: white;

        @include mobile {
            padding: 0;
        }
    }

    &-Heading {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 27px;
        letter-spacing: 0.05em;
        color: #161616;

        @include mobile {
            display: none;
        }
    }

    &-Items {
        display: flex;
        flex-direction: column;
        row-gap: 24px;
        @include mobile {
            row-gap: 0px;
        }
    }

    &-ExpandableContentContent {
        margin-block-start: 0;
    }

    .ProductLinks-Wrapper {
        padding-inline: 0;

        @include desktop {
            padding-inline: 32px;
        }
    }

    .ProductLinks-List {
        grid-column-gap: 16px;
        z-index: 10;

        .ProductCard-ProductActions {
            display: flex;
        }

        .AddToCart {
            width: 100%;
            margin-inline-end: 0;
        }
    }

    .CheckoutOrderSummary {
        .Loader {
            display: none;
        }
    }
}
